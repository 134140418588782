<template>
    <div :class="['CEESAR-ApplicationLogo', url!=undefined? 'CEESAR-ApplicationLogoClickable' : '']" v-on:click="OnClick">
        <img :src="finalImageSource"/>
    </div>
</template>

<script>
export default {
    name: "CEESAR-ApplicationLogo",
    props:{
        imageSource:{ type: String, default: ""},
        url: {type: String, default: undefined}
    },
    data(){
        return{
            finalImageSource: ""
        };
    },
    mounted(){
        this.finalImageSource = this.imageSource;
        if(this.finalImageSource == "")
        {
            this.finalImageSource = require('../assets/images/ceesar_logo.png');
        }
    },
    methods:
    {
        OnClick()
        {
            if(this.url != undefined)
            {
                window.location = this.url;
            }
            else
            {
                this.$emit("click");
            }
        }
    }
}
</script>

<style>
</style>